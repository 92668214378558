import toast from "react-hot-toast";
import { MAP_API } from "../config/config";
import { store } from "../redux/store";
import api from "../API/apiCollection";
import { useSelector } from "react-redux";
import dummy from '../../src/Images/dummyUser.jpeg'
export function capilaizeString(inputString) {
  if (typeof inputString !== "string" || inputString?.length === 0) {
    return inputString;
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export async function getFormattedAddress(lat, lng) {
  try {
    const response = await api.getPlacesDetailsForWebApi({
      latitude: lat,
      longitude: lng,
    });
    const data = await response?.data?.data;
    const formattedAddress = data.results[0]?.formatted_address;

    return formattedAddress;
  } catch (error) {
    console.error("Error fetching address:", error);
    return null;
  }
}

export const truncate = (text, maxLength) => {
  // Check if text is undefined or null
  if (!text) {
    return ""; // or handle the case as per your requirement
  }

  // If the text length is less than or equal to maxLength, return the original text
  if (text?.length <= maxLength) {
    return text;
  } else {
    // Otherwise, truncate the text to maxLength characters and append ellipsis
    return text.slice(0, maxLength) + "...";
  }
};

const ERROR_CODES = {
  "auth/user-not-found": "User not found",
  "auth/wrong-password": "Invalid password",
  "auth/email-already-in-use": "Email already in use",
  "auth/invalid-email": "Invalid email address",
  "auth/user-disabled": "User account has been disabled",
  "auth/too-many-requests": "Too many requests, try again later",
  "auth/operation-not-allowed": "Operation not allowed",
  "auth/internal-error": "Internal error occurred",
};

// Error handling function
export const handleFirebaseAuthError = (errorCode) => {
  // Check if the error code exists in the global ERROR_CODES object
  if (ERROR_CODES.hasOwnProperty(errorCode)) {
    // If the error code exists, log the corresponding error message
    toast.error(ERROR_CODES[errorCode]);
  } else {
    // If the error code is not found, log a generic error message
    toast.error(`Unknown error occurred: ${errorCode}`);
  }

  // Optionally, you can add additional logic here to handle the error
  // For example, display an error message to the user, redirect to an error page, etc.
};

export const getAuthErrorMessage = (errorCode) => {
  switch (errorCode) {
    case "auth/billing-not-enabled":
      return toast.error("Billing is not enabled");
    case "auth/admin-restricted-operation":
      return toast.error("Admin Only Operation");
    case "auth/already-initialized":
      return toast.error("Already Initialized");
    case "auth/app-not-authorized":
      return toast.error("App Not Authorized");
    case "auth/app-not-installed":
      return toast.error("App Not Installed");
    case "auth/argument-error":
      return toast.error("Argument Error");
    case "auth/captcha-check-failed":
      return toast.error("Captcha Check Failed");
    case "auth/invalid-verification-code":
      return toast.error("Invalid Verification Code");
    case "auth/invalid-phone-number":
      return toast.error("Invalid Phone Number");
    case "auth/missing-phone-number":
      return toast.error("Missing Phone Number");
    case "auth/quota-exceeded":
      return toast.error("Quota Exceeded");
    case "auth/too-many-requests":
      return toast.error("Too many requests, please try again later");
    case "auth/network-request-failed":
      return toast.error("Network request failed");
    default:
      return toast.error("An unknown error occurred");
  }
};

// urlTypeHelper.js
const imageTypes = [
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  "gif",
  "apng",
  "webp",
  "avif",
];

const videoTypes = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m3u8",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];

export const UrlType = {
  IMAGE: "image",
  VIDEO: "video",
  UNKNOWN: "unknown",
};
export const getUrlType = (url) => {
  try {
    const urlObj = new URL(url);
    let extension = urlObj.pathname.split(".").pop().toLowerCase();

    if (!extension) {
      return UrlType.UNKNOWN;
    }

    if (imageTypes.includes(extension)) {
      return UrlType.IMAGE;
    } else if (videoTypes.includes(extension)) {
      return UrlType.VIDEO;
    }
  } catch (error) {
    console.error("Error determining URL type:", error);
    return UrlType.UNKNOWN;
  }

  return UrlType.UNKNOWN;
};

export const getStatusClassName = (status) => {
  let className = "";
  switch (status) {
    case "awaiting":
      className = "status-awaiting cursor-none";
      break;
    case "confirmed":
      className = "status-success cursor-none";
      break;
    case "completed":
      className = "status-success cursor-none";
      break;
    case "cancelled":
      className = "status-error cursor-none";
      break;
    case "rescheduled":
      className = "status-warning cursor-none";
      break;
    case "started":
      className = "status-dark cursor-none";
      break;
    default:
      className = "status-info cursor-none";
      break;
  }
  return className;
};

export const normalizeSlugTerm = (term) => {
  return term.replace(/-/g, " ");
};

export const placeholderImage = (e) => {
  let settings = store.getState()?.Settings?.settings?.web_settings;
  const placeholderLogo = settings?.web_half_logo;
  if (placeholderLogo) {
    e.target.onerror = null; 
    e.target.src = placeholderLogo;
    e.target.style.opacity = 0.5; // Adjust opacity when the placeholder image is set
    e.target.style.width = "100%";
    e.target.style.objectFit = "contain";
  }
};

export const getPaymentMethodName = (method) => {
  switch (method) {
    case "cod":
      return "Pay Later";
    case "stripe":
      return "Stripe";
    case "razorpay":
      return "Razor Pay";
    case "paystack":
      return "Pay Stack";
    case "paypal":
      return "Pay Pal";
    case "flutterwave":
      return "Flutterwave";
    default:
      return method; // If the method doesn't match, show it as is
  }
};

export const formatAmount = (amount) => {
 // Convert string to number if necessary
 const num = typeof amount === "string" ? Number(amount) : amount;
 
 // Ensure the converted value is a valid number
 if (isNaN(num)) {
   throw new Error("Invalid number");
 }

 // Format to 2 decimal places and remove trailing zeros
 return parseFloat(num.toFixed(2));
}

export const customLog = (...args) => {
  if (process.env.REACT_APP_PRINT_CONSOLE_LOGS === "true") {
    console.log(...args);
  }
};


export const useIsRTL = () => {
  const currentLanguage = useSelector((state) => state.language.currentLanguage);
  return currentLanguage?.isRtl === true;
};

