import React, { useEffect, useState } from "react";
import Pnavigation from "./Pnavigation";
import Layout from "../../layout/Layout";
import {
  Box,
  Breadcrumbs,
  Container,
  Drawer,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { BreadcrumbLink } from "../../../CSS/ThemeStyle";
import { Divider } from "antd";
import { MdCancel } from "react-icons/md";
import BiderCard from "../Cards/BiderCard";
import api from "../../../API/apiCollection";
import { useNavigate, useParams } from "react-router";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import BiderCardSkeleton from "../Cards/BiderCardSkeleton";
import BookingInfoDrawerNew from "../../../Drawers/BookingInfoDrawerNew";
import { handleClose, handleOpen } from "../../../config/config";
import AddressDrawer from "../../../Drawers/AddressDrawer";
import AddAddressForm from "../../../Drawers/AddAddressForm";

import {
  addCustomJobReqData,
  handleCustomJob,
} from "../../../redux/isCustomJobRequest";
import CustomJobReqInfoDrawer from "../../../Drawers/CustomJobReqInfoDrawer";
import ConfirmDateTime from "../../../Drawers/ConfirmDataTime";
import { useIsRTL } from "../../../util/Helper";

const MySericeReqDetail = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [serviceData, setServiceData] = useState();
  const [biddersData, setBiddersData] = useState([]);
  const limit = 5;
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState("");

  const settings = useSelector((state) => state.Settings)?.settings;
  const currency_symbol = settings?.app_settings?.currency;

  const isRtl = useIsRTL();

  const [form, setForm] = useState(false);
  const [selectSlote, isSelectedSlote] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [addressFrom, setAddressForm] = useState(false);
  const [booking, setBooking] = useState(false);

  const fetchDetails = async (reset = false) => {
    setIsLoading(true);
    try {
      const BiddersResponse = await api.fetchMyCustomJobBiddersApi({
        custom_job_request_id: slug,
        limit: limit,
        offset: reset ? 0 : offset, // Set offset to 0 if resetting
      });
      const data = BiddersResponse?.data;
      setTotal(data?.total);
      // If resetting, clear the current bidders and replace with new data
      if (reset) {
        setBiddersData(data?.data?.bidders || []);
      } else {
        // Get the new bidders
        const newBidders = data?.data?.bidders || [];
        // Append unique new bidders
        setBiddersData((prev) => [...prev, ...newBidders]);
      }

      setServiceData(data?.data?.custom_job);

      // Update hasMore based on the response length
      if (data?.data?.bidders.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchDetails(true); // Fetch details initially
    }
  }, [slug]);

  const handleDeleteReq = async (id) => {
    try {
      const response = await api.cancelCustomJobReqApi({
        custom_job_request_id: id,
      });
      if (response?.data?.error === false) {
        toast.success(response?.data?.message);
        navigate("/profile/my-services");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  const handleLoadMore = () => {
    setOffset((prevOffset) => prevOffset + limit); // Increase offset
  };
  useEffect(() => {
    if (offset > 0) {
      fetchDetails(); // Fetch the next set of data when offset changes
    }
  }, [offset]);

  const ServiceDetailsSkeleton = () => {
    return (
      <Box className="serviceDetails" sx={{ padding: 3 }}>
        <Box
          className="serviceTitleandStatus"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box className="commanserviceDiv" sx={{ flex: 1 }}>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" sx={{ mt: 1 }} />
          </Box>
          <Box className="serviceStatus" sx={{ flexShrink: 0 }}>
            <Skeleton variant="rectangular" width={100} height={40} />
          </Box>
        </Box>

        <Box className="serviceInfo" sx={{ mb: 2 }}>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-3">
              <Box className="commanserviceDiv" sx={{ mb: 1, width: "100%" }}>
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="60%" sx={{ mt: 1 }} />
              </Box>
            </div>
            <div className="col-3">
              <Box className="commanserviceDiv" sx={{ mb: 1, width: "100%" }}>
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="60%" sx={{ mt: 1 }} />
              </Box>
            </div>
            <div className="col-3">
              <Box className="commanserviceDiv" sx={{ mb: 1, width: "100%" }}>
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="60%" sx={{ mt: 1 }} />
              </Box>
            </div>
            <div className="col-3">
              <Box className="commanserviceDiv" sx={{ mb: 1, width: "100%" }}>
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="60%" sx={{ mt: 1 }} />
              </Box>
            </div>
          </div>
        </Box>

        <Box className="serviceDesc" style={{ width: "100%" }}>
          <Box className="commanserviceDiv" style={{ width: "100%" }}>
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="80%" sx={{ mt: 1 }} />
          </Box>
        </Box>
      </Box>
    );
  };

  function ContinueClicked() {
    handleOpen(setForm);
  }

  function BookingDetails() {
    handleClose(setForm);
    handleOpen(setBooking);
  }

  function BookingDrawer() {
    handleClose(isSelectedSlote);
    handleOpen(setBooking);
  }
  function OpenMapDrawer() {
    handleOpen(setAddAddress);
    handleClose(setForm);
  }

  function CompleteAddress() {
    handleClose(setAddAddress);
    handleOpen(setAddressForm);
  }

  const handleAddBidder = (data) => {
    dispatch(handleCustomJob(true));
    dispatch(addCustomJobReqData(data));
    // setForm(true)
    ContinueClicked();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
    const year = date.getFullYear(); // Get full year

    return `${day}/${month}/${year}`;
  };
  const formatTime = (timeString) => {
    
    const time = new Date(`1970-01-01T${timeString}Z`); // Convert to a Date object
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const period = hours >= 12 ? "PM" : "AM"; // Determine AM or PM

    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12; // Handle 00 hours (midnight) as 12 AM
    minutes = String(minutes).padStart(2, "0"); // Add leading zero to minutes

    return `${hours}:${minutes} ${period}`;
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded((prevState) => !prevState);
  };
  const description = serviceData?.service_short_description || "";
  const isLongDescription = description.length > 100;
  return (
    <Layout>
      <Box paddingTop={"35px"} paddingBottom={"35px"} mt={2}>
        <Container maxWidth="lg" className="mainContainer">
          <Breadcrumbs
            separator="|"
            aria-label="breadcrumb"
            className="mb-1 mt-1"
          >
            <BreadcrumbLink to={"/"} className="breadcrumb" sx={{ mb: 0 }}>
              <strong>{t("home")}</strong>
            </BreadcrumbLink>
            <Typography color="text.primary">
              <strong>{t("profile")}</strong>
            </Typography>
          </Breadcrumbs>
          <Typography variant="h4" gutterBottom={true} sx={{ mt: "12px" }}>
            <strong>{t("myservicedetail")}</strong>
          </Typography>
        </Container>
      </Box>

      <Container className="mainContainer" sx={{ mb: "30px", mt: "-50px" }}>
        <Grid spacing={3} container>
          <Grid item xs={12} md={4}>
            <Pnavigation />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{ background: theme.palette.background.box }}
              mt={3}
              minHeight={570}
              borderRadius={4}
            >
              <div>
                <div className="headlineDiv">
                  <Typography variant="h5" fontWeight={"bold"} mb={2} pt={2}>
                    {t("myReqServiceDetails")}
                  </Typography>
                  {!isLoading && serviceData?.status === "pending" && (
                    <button
                      className="removemyservice"
                      onClick={() => handleDeleteReq(serviceData?.id)}
                    >
                      <span>{t("cancelService")}</span>
                      <span>
                        <MdCancel size={22} />
                      </span>
                    </button>
                  )}
                </div>
                <Divider />
              </div>
              <Box padding={3}>
                {isLoading ? (
                  ServiceDetailsSkeleton()
                ) : (
                  <div className="serviceDetails">
                    <div className="serviceTitleandStatus">
                      <div className="commanserviceDiv">
                        <span className="commanLbl">{t("serviceTitle")}</span>
                        <span className="commanValue">
                          {serviceData?.service_title}
                        </span>
                      </div>
                      <div className="serviceStatus">
                        {serviceData?.status === "pending" ? (
                          <div className="pending_status">
                            <span>{t("requested")}</span>
                          </div>
                        ) : serviceData?.status === "cancelled" ? (
                          <div className="cancelled_status">
                            <span>{t("cancelled")}</span>
                          </div>
                        ) : serviceData?.status === "booked" ? (
                          <div className="approved_status">
                            <span>{t("booked")}</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="serviceInfo">
                      <div className="commanserviceDiv">
                        <span className="commanLbl">
                          {t("servideCategory")}
                        </span>
                        <span className="commanValue">
                          {serviceData?.category_name}
                        </span>
                      </div>
                      <div className="commanserviceDiv">
                        <span className="commanLbl">{t("price")}</span>
                        <span className="commanValue">
                          {currency_symbol} {""} {serviceData?.min_price} -{" "}
                          {currency_symbol} {""} {serviceData?.max_price}
                        </span>
                      </div>
                      <div className="commanserviceDiv">
                        <span className="commanLbl">{t("reqStartTime")}</span>
                        <span className="commanValue">
                          {" "}
                          {formatDate(serviceData?.requested_start_date)},{" "}
                          {serviceData?.requested_start_time}
                        </span>
                      </div>
                      <div className="commanserviceDiv">
                        <span className="commanLbl">{t("reqEndTime")}</span>
                        <span className="commanValue">
                          {formatDate(serviceData?.requested_end_date)},{" "}
                          {serviceData?.requested_end_time}
                        </span>
                      </div>
                    </div>
                    <div className="serviceDesc">
                      <div
                        className={`commanserviceDiv ${
                          isExpanded ? "expanded" : ""
                        }`}
                      >
                        <span className="commanLbl">{t("desc")}</span>
                        <span className="commanValue">
                          {isExpanded
                            ? description
                            : `${description.slice(0, 100)}`}
                        </span>
                      </div>
                      {isLongDescription && (
                        <button
                          className="viewMoreBtn"
                          onClick={toggleDescription}
                        >
                          {isExpanded ? t("view_less") : t("view_more")}
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {isLoading ? (
                  // Show skeletons while loading
                  <div className="bidersDeatils">
                    <div className="heading">
                      <span>{t("providerBidderList")}</span>
                      <span className="count">
                        {total} {t("bidders")}
                      </span>
                    </div>
                    <div className="bidersList">
                      <div className="row" style={{ rowGap: "20px" }}>
                        {/* Render multiple skeletons to simulate loading */}
                        {Array.from({ length: 6 }).map((_, index) => (
                          <div className="col-12" key={index}>
                            <BiderCardSkeleton />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  biddersData.length > 0 && (
                    <div className="bidersDeatils">
                      <div className="heading">
                        <span>{t("providerBidderList")}</span>
                        <span className="count">
                          {total} {t("bidders")}
                        </span>
                      </div>
                      <div className="bidersList">
                        <div className="row" style={{ rowGap: "20px" }}>
                          {biddersData.map((ele, index) => (
                            <div className="col-12" key={index}>
                              <BiderCard
                                data={ele}
                                currency_symbol={currency_symbol}
                                status={serviceData?.status}
                                handleAddBidder={handleAddBidder}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      {hasMore && (
                        <div className="loadMoreService">
                          <button onClick={handleLoadMore} disabled={isLoading}>
                            {isLoading ? t("loading") : t("loadMore")}
                          </button>
                        </div>
                      )}
                    </div>
                  )
                )}
              </Box>
            </Box>

            {/* Select Date and time and based on that We send request to check available slot */}

            <Drawer
              open={form}
              anchor={isRtl ? "left" : "right"}
              sx={{
                display: { xs: "block", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: { md: 580, xs: "100%" },
                },
              }}
            >
              <Box>
                <AddressDrawer
                  setForm={setForm}
                  isSelectedSlote={isSelectedSlote}
                  continueFun={BookingDetails}
                  MyFun={OpenMapDrawer}
                  setBooking={setBooking}
                />
                {/* <CustomJobReqInfoDrawer close={()=>setForm(false)}/> */}
              </Box>
            </Drawer>

            {/* In this drawer we provide them clanader and list all available time slotes and on select another request send to same api */}
            <Drawer
              open={selectSlote}
              anchor={isRtl ? "left" : "right"}
              sx={{
                display: { xs: "block", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: { md: 580, xs: "100%" },
                },
              }}
            >
              <Box>
                <ConfirmDateTime
                  setForm={setForm}
                  isSelectSlote={isSelectedSlote}
                  booking={BookingDrawer}
                />
              </Box>
            </Drawer>

            {/* if user want to address than add address drawer  */}
            <Drawer
              open={addAddress}
              anchor={isRtl ? "left" : "right"}
              sx={{
                display: { xs: "block", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: { md: 580, xs: "100%" },
                },
              }}
            >
              <Box>
                <AddAddressForm
                  CompleteAddress={CompleteAddress}
                  setForm={setForm}
                  addAddress={setAddAddress}
                />
              </Box>
            </Drawer>
            {/* booking information drawer  */}

            <Drawer
              open={booking}
              anchor={isRtl ? "left" : "right"}
              sx={{
                display: { xs: "block", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: { md: 580, xs: "100%" },
                },
              }}
            >
              <Box>
                <BookingInfoDrawerNew
                  setForm={setForm}
                  setPromo={""}
                  setBooking={setBooking}
                />
              </Box>
            </Drawer>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default MySericeReqDetail;
