import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
} from "@mui/material";
import { t } from "i18next";
import api from "../API/apiCollection";
import toast from "react-hot-toast";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../redux/cart";
import { orderCartDetailsReset } from "../redux/orderCartDetails";
import { deliveryAddressReset } from "../redux/DeliveryAddress";
import { resetStatePromo } from "../redux/Promocode";
import { IoCloseCircle } from "react-icons/io5";

const CheckoutForm = ({
  order_id,
  clientKey,
  clientSecret,
  paymentMethod,
  isAdditionalCharges,
  transactionId,
  isCustomJobRequestID,
  isReOrder,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const theme = useTheme();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      console.error("Stripe has not loaded yet.");
      setLoading(false);
      return;
    }

    await elements.submit();

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/success`,
        },
        redirect: "if_required",
        clientSecret,
      });

      if (error) {
        setLoading(false);
        toast.error(`Payment failed: ${error.message}`);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        toast.success("Payment Successful!");
        await api
          .add_transactions({
            orderID: order_id,
            status: "success",
            is_additional_charge: isAdditionalCharges ? 1 : "",
            payment_method: paymentMethod,
            transaction_id: isAdditionalCharges ? transactionId : "",
            is_reorder: isReOrder ? "1" : "",
          })
          .then((response) => {
            dispatch(resetState());
          })
          .then((res) => {
            dispatch(resetState());
            dispatch(orderCartDetailsReset());
            dispatch(deliveryAddressReset());
            dispatch(resetStatePromo());
            setLoading(false);
            navigate(`/payment-status?status=successful&order_id=${order_id}`);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box sx={{ color: "white" }}>
          <AddressElement options={{ mode: "billing" }} className="mt20 mb20" />
          <PaymentElement className="mt20 mb20" />
        </Box>

        <Button
          type="submit"
          disabled={!stripe || !elements || loading === true}
          variant="outlined"
          fullWidth
          sx={{
            mt: 2,
            borderRadius: "8px",
            backgroundColor: theme.palette.background.buttonColor,
          }}
        >
          {t("make_a_payment")}
        </Button>
      </form>
    </>
  );
};

function Stripe({
  amount,
  isAdditionalCharges,
  additionalServiceOrderId,
  paymentMethod,
  transactionId,
  orderID,
  open,
  setOpen,
  clientKey,
  isCustomJobRequest,
  customJobReqData,
  isReOrder,
  renderStripeElement,
  setrenderStripeElement
}) {
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = async (e) => {
    e.preventDefault();
    setOpen(false);
    setrenderStripeElement(false)
    await api
      .add_transactions({ orderID: orderID, status: "cancelled" })
      .then((response) => {});
  };

  useEffect(() => {
    if (renderStripeElement) {
      setOpen(true);
    }
  }, [renderStripeElement]);

  return (
    <>
      {/* <Button
        variant="outlined"
        fullWidth
        sx={{ my: 1 }}
        onClick={(e) => handleOpen()}
      >
        {t("make_a_payment")} {t("with")} {t("stripe")}
      </Button> */}

      <Dialog open={open} maxWidth={"lg"}>
        <Box width={{ xs: "100%", md: 500 }}>
          <DialogTitle
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6"> {t("pay_with_stripe")} </Typography>
            <IconButton
              aria-label="handle-close"
              onClick={(e) => handleClose(e)}
            >
              <IoCloseCircle size={18} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box my={5} sx={{ color: "white " }}>
              <CheckoutForm
                amount={amount}
                order_id={
                  isAdditionalCharges ? additionalServiceOrderId : orderID
                }
                clientKey={clientKey}
                paymentMethod={paymentMethod}
                isAdditionalCharges={isAdditionalCharges}
                transactionId={transactionId}
                isCustomJobRequestID={
                  isCustomJobRequest ? customJobReqData?.id : ""
                }
                isReOrder={isReOrder}
              />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

export default Stripe;
